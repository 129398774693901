import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    IconButton,
    Button,
    ListSubheader
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import Layout from "../Util/Layout";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

// Utility function to convert "file_name.md" -> "file name"
function formatFileName(fileName) {
    return fileName.replace(/_/g, " ").replace(/\.[^/.]+$/, "");
}

const ArticlesPage = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [articleStructure, setArticleStructure] = useState({});
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [markdownContent, setMarkdownContent] = useState("");

    /**
     * On mount:
     *  - Use Webpack's require.context to load all .md files (including subdirectories).
     *  - Build an object structure: { "Topic1": [...], "General": [{ name, fullPath }, ...], etc. }.
     */
    useEffect(() => {
        try {
            const markdownContext = require.context(
                "../../Assets/Articles",
                true, // Include subdirectories
                /\.md$/
            );
            const tempStructure = {};

            markdownContext.keys().forEach((key) => {
                // key format = "./TopicName/article_name.md" or "./someArticle.md"
                const pathWithoutDot = key.replace("./", ""); // -> "TopicName/article_name.md" or "someArticle.md"
                const pathSegments = pathWithoutDot.split("/");

                let directory, fileName;
                if (pathSegments.length === 1) {
                    // No subdirectory
                    directory = "General";
                    fileName = pathSegments[0];
                } else {
                    // First segment is subdirectory, second is filename
                    [directory, fileName] = pathSegments;
                }

                if (!tempStructure[directory]) {
                    tempStructure[directory] = [];
                }

                tempStructure[directory].push({
                    name: fileName,         // e.g. "Welcome_Article.md"
                    fullPath: pathWithoutDot // e.g. "Welcome_Article.md" or "Topic1/article1.md"
                });
            });

            setArticleStructure(tempStructure);
        } catch (error) {
            console.error("Error loading Markdown files:", error);
        }
    }, []);

    /**
     * Once we have articleStructure, automatically select "Welcome_Article.md" from "General" if available.
     * This only happens if there is no article already selected.
     */
    useEffect(() => {
        if (!selectedArticle && articleStructure.General) {
            const welcomeArticle = articleStructure.General.find(
                (item) => item.name === "Welcome_Article.md"
            );
            if (welcomeArticle) {
                setSelectedArticle(welcomeArticle);
            }
        }
    }, [articleStructure, selectedArticle]);

    /**
     * When an article is selected:
     *  - Set the selectedArticle (contains { name, fullPath }).
     *  - Close the drawer to show the content in the main area.
     */
    const handleArticleClick = (articleObj) => {
        setSelectedArticle(articleObj);
        setDrawerOpen(false);
    };

    /**
     * Whenever selectedArticle changes, load the MD content via dynamic import + fetch.
     */
    useEffect(() => {
        if (!selectedArticle) return;

        const loadMarkdown = async () => {
            try {
                // e.g. "Welcome_Article.md" or "Topic1/article1.md"
                const module = await import(`../../Assets/Articles/${selectedArticle.fullPath}`);
                const response = await fetch(module.default);
                const text = await response.text();
                setMarkdownContent(text);
            } catch (error) {
                console.error("Error fetching article:", error);
                setMarkdownContent("Failed to load the selected article.");
            }
        };

        loadMarkdown();
    }, [selectedArticle]);

    return (
        <Layout>
            <Box p={3}>
                {/* Removed the "My Articles" title previously */}

                {/* Button to open the right-side Drawer */}
                <Box mb={2}>
                    <Button
                        variant="contained"
                        startIcon={<MenuIcon />}
                        onClick={() => setDrawerOpen(true)}
                    >
                        Show Articles
                    </Button>
                </Box>

                {/* Main content: Render the selected markdown */}
                {markdownContent ? (
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {markdownContent}
                    </ReactMarkdown>
                ) : (
                    <Typography>Select an article from the drawer to view its contents.</Typography>
                )}
            </Box>

            {/* Collapsible Sidebar on the right */}
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{
                    sx: {
                        width: 300,
                        mt: 8, // Adjust to avoid overlapping your top bar
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                    },
                }}
            >
                {/* Close Button */}
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <IconButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Nested list of Markdown files by directory */}
                <List disablePadding>
                    {Object.entries(articleStructure).map(([directory, articles]) => (
                        <React.Fragment key={directory}>
                            <ListSubheader
                                sx={{
                                    bgcolor: "background.paper",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                }}
                            >
                                {directory === "General" ? "General Articles" : formatFileName(directory)}
                            </ListSubheader>

                            {articles.map((article) => (
                                <ListItemButton
                                    key={article.name}
                                    onClick={() => handleArticleClick(article)}
                                    selected={selectedArticle?.name === article.name}
                                >
                                    <ListItemText primary={formatFileName(article.name)} />
                                </ListItemButton>
                            ))}
                        </React.Fragment>
                    ))}
                </List>
            </Drawer>
        </Layout>
    );
};

export default ArticlesPage;

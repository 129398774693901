import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Components/Pages/HomePage";
import theme from "./theme";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@mui/material/styles";
import MissionReviewPage from "./Components/Pages/MissionReviewPage";
import AiPremiumCalculationPage from "./Components/Pages/AiPremiumCalculationPage";
import AWCSDRPage from "./Components/Pages/AWCSDRPage";
import InventreeToolkitPage from "./Components/Pages/InventreeToolkitPage";
import AlanPage from "./Components/Pages/AlanPage";
import PersonalWebsitePage from "./Components/Pages/PersonalWebsitePage";
import EducationPage from "./Components/Pages/EducationPage";
import PersonalLifePage from "./Components/Pages/PersonalLifePage";
import ContactPage from "./Components/Pages/ContactPage";
import EngineeringExperience from "./Components/Pages/EngineeringExperience";
import ArticlesPage from "./Components/Pages/ArticlesPage";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Nick Colleran</title>
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="mission-review" element={<MissionReviewPage />} />
          <Route
            path="ai-premium-calculation"
            element={<AiPremiumCalculationPage />}
          />
          <Route path="water-collaborative" element={<AWCSDRPage />} />
          <Route path="inventree-toolkit" element={<InventreeToolkitPage />} />
          <Route path="ala-navigator" element={<AlanPage />} />
          <Route path="personal-website" element={<PersonalWebsitePage />} />
          <Route path="education" element={<EducationPage />} />
          <Route path="personal-life" element={<PersonalLifePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="articles" element={<ArticlesPage />} />
          <Route
            path="engineering-experience"
            element={<EngineeringExperience />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
